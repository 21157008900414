export default [
  {
    header: 'navigation.menu.meli.home',
    icon: ['fas', 'store'],
    children: [
      /*
      {
        title: 'navigation.menu.meli.dashboard',
        route: 'meli-dashboard',
        icon: ['fas', 'digital-tachograph'],
      },
      */
      {
        title: 'navigation.menu.meli.analytics.home',
        route: 'meli-analytics',
        icon: ['fas', 'cubes'],
        children: [
          {
            title: 'navigation.menu.meli.analytics.charts',
            icon: ['fas', 'chart-line'],
            route: 'meli-analytics-charts',
          },
          {
            title: 'navigation.menu.meli.analytics.sales_reports',
            icon: ['fas', 'list-alt'],
            route: 'meli-analytics-sales-reports',
          },
          {
            title: 'navigation.menu.meli.analytics.competitors',
            icon: ['fas', 'store-alt'],
            route: 'meli-analytics-competitors',
          },
        ],
      },
      {
        title: 'navigation.menu.meli.sales',
        route: 'meli-sales',
        icon: ['fas', 'shopping-cart'],
      },
      {
        title: 'navigation.menu.meli.mp.home',
        route: 'meli-mp',
        icon: ['fas', 'wallet'],
        children: [
          {
            title: 'navigation.menu.meli.mp.collections',
            icon: ['fas', 'dollar-sign'],
            route: 'meli-mp-collections',
          },
        ],
      },
      {
        title: 'navigation.menu.meli.listings',
        route: 'meli-listings',
        icon: ['fas', 'tags'],
      },
      {
        title: 'navigation.menu.meli.questions',
        route: 'meli-questions',
        icon: ['far', 'question-circle'],
      },
      {
        title: 'navigation.menu.meli.settings.home',
        route: 'meli-settings',
        icon: ['fas', 'cog'],
        children: [
          {
            title: 'navigation.menu.meli.settings.profile',
            icon: ['fas', 'user'],
            route: 'meli-accounts-profile',
          },
          {
            title: 'navigation.menu.meli.settings.import',
            icon: ['fas', 'cloud-download-alt'],
            route: 'meli-settings-import',
          },
          {
            title: 'navigation.menu.meli.settings.blocklists',
            icon: ['fas', 'user-slash'],
            route: 'meli-settings-blocklists',
          },
        ],
      },
    ],
  },
]
