<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <span class="brand-logo">
            <b-img :src="imgUrl" :alt="appName" />
          </span>
          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import variants from '@core-md/mixins/variants'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BImg,
  },
  mixins: [
    variants,
  ],
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      appLogoImageDark,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return this.appLogoImageDark
      }
      return this.appLogoImage
    },
  },
}
</script>

<style>

</style>
